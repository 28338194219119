/* Common style */

html {
    box-sizing: border-box;
    background-color: #000;
  }
  
  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }
  
* { outline: none; }
body { margin: 0; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 16px; color: #fff; }
form { margin: 0 auto; max-width: 800px; padding: 30px; }
.mt-lg { margin-top: 60px; }
.danger { color: red; }
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} 
