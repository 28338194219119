.dashboard-sec {
  background-color: #111111;
  color: #fff;
  padding: 15px 0;
}

.dice-bet-amount .rc-input-number-input {
  width: 100%;
  background: transparent;
  border: none;
  text-align: center;
  color: white;
}

.dice-losing-result-span {
  color: red !important;
}

.input-range {
  height: 2.0rem !important;
}

.dice-bet-button {
  border: 0px;
  font-size: 1.0rem;
  font-weight: 550;
  color: #252525;
  background-color: #CBE42B;
}


.dice-bet-button-disabled {
  color: #2c2727 !important;
  background-color: #6c7823 !important;
}

.input-range__track {
  background: red !important;
  border-color: red !important;
}

.input-range__track--active {
  background: #CBE43C !important;
  border-color: #CBE43C !important;
}

.input-range__track--background {
  margin-top: -0.55rem !important;
}

.input-range__track {
  height: 0.8rem !important;
}

.input-range__slider {
  background: black !important;
  border: 0.2rem solid #CBE43C !important;
  height: 2rem !important;
  width: 2rem !important;
  margin-top: -1.2rem !important;
  pointer-events: auto;
}

.input-range__label input-range__label--value {
  pointer-events: none;
}

.input-range__label {
  font-size: 1.2rem !important;
  pointer-events: none;
}

.input-range__label--value {
  z-index: 100;
}

.input-range__label--value .input-range__label-container {
  left: -50%;
  margin-left: 1rem;
  position: relative;
  top: 0.75rem;
  color: white;
  font-weight: bolder;
  pointer-events: none;
}

.input-range__label--min, .input-range__label--max {
  bottom: 0.2rem !important;
  color: white !important;
  font-weight: bolder;
}

.input-range__label--max {
  right: -1.8rem !important;
}

.input-range__label--min {
  left: -1.8rem !important;
}

.body-wrapper {
  background-color: #111111 !important;
}

.switch {
  margin: 0 5px !important;
}

.switch {
  margin: 4rem auto;
}

/* main styles */

.switch {
  width: 24rem;
  position: relative;
}

.switch input {
  position: absolute;
  top: 0;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}

.switch input:checked {
  z-index: 1;
}

.switch input:checked+label {
  opacity: 1;
  cursor: default;
}

.switch input:not(:checked)+label:hover {
  opacity: 0.5;
}

.switch label {
  color: #fff;
  opacity: 0.33;
  transition: opacity 0.25s ease;
  cursor: pointer;
}

.switch .toggle-outside {
  height: 100%;
  border-radius: 2rem;
  padding: 0.25rem;
  overflow: hidden;
  transition: 0.25s ease all;
}

.switch .toggle-inside {
  border-radius: 5rem;
  background: #4a4a4a;
  position: absolute;
  transition: 0.25s ease all;
}

.switch--horizontal {
  width: 18rem;
  height: 3rem;
  margin: 0 auto;
  font-size: 0;
  margin-bottom: 1rem;
}

.switch--horizontal input {
  height: 3rem;
  width: 6rem;
  left: 6rem;
  margin: 0;
}

.switch--horizontal label {
  font-size: 1.5rem;
  line-height: 3rem;
  display: inline-block;
  width: 6rem;
  height: 100%;
  margin: 0;
  text-align: center;
}

.switch--horizontal label:last-of-type {
  margin-left: 6rem;
}

.switch--horizontal .toggle-outside {
  background: #fff;
  position: absolute;
  width: 6rem;
  left: 6rem;
}

.switch--horizontal .toggle-inside {
  height: 2.5rem;
  width: 2.5rem;
}

.switch--horizontal input:checked~.toggle-outside .toggle-inside {
  left: 0.25rem;
}

.switch--horizontal input~input:checked~.toggle-outside .toggle-inside {
  left: 3.25rem;
}

.switch--vertical {
  width: 12rem;
  height: 1.4rem;
}

.switch--vertical input {
  height: 100%;
  width: 3rem;
  right: 0;
  margin: 0;
}

.switch--vertical label {
  font-size: 1.5rem;
  line-height: 3rem;
  display: block;
  width: 8rem;
  height: 50%;
  margin: 0;
  text-align: center;
}

.switch--vertical .toggle-outside {
  background: #252525;
  position: absolute;
  width: 3rem;
  height: 100%;
  right: 0;
  top: 0;
}

.switch--vertical .toggle-inside {
  height: 1.2rem;
  left: 0;
  top: 0;
  width: 1.2rem;
}

.switch--vertical input:checked~.toggle-outside .toggle-inside {
  top: 0.25rem;
}

.switch--vertical input~input:checked~.toggle-outside .toggle-inside {
  top: 1.45rem;
}

.switch--no-label label {
  width: 0;
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.switch--no-label input:checked~.toggle-outside .toggle-inside {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.switch--no-label input~input:checked~.toggle-outside {
  background: #fff;
}

.switch--no-label input~input:checked~.toggle-outside .toggle-inside {
  background: #2ecc71;
}

.switch--no-label.switch--vertical {
  width: 3rem;
}

.switch--no-label.switch--horizontal {
  width: 6rem;
}

.switch--no-label.switch--horizontal input, .switch--no-label.switch--horizontal .toggle-outside {
  left: 0;
}

.switch--expanding-inner input:checked+label:hover~.toggle-outside .toggle-inside {
  height: 2.5rem;
  width: 2.5rem;
}

.switch--expanding-inner.switch--horizontal input:hover~.toggle-outside .toggle-inside {
  width: 3.5rem;
}

.switch--expanding-inner.switch--horizontal input:hover~input:checked~.toggle-outside .toggle-inside {
  left: 2.25rem;
}

.switch--expanding-inner.switch--vertical input:hover~.toggle-outside .toggle-inside {
  height: 3.5rem;
}

.switch--expanding-inner.switch--vertical input:hover~input:checked~.toggle-outside .toggle-inside {
  top: 1.25rem;
}

.total-col1 .total-col-inner2 p {
  margin-left: -2px;
}