.visible-xs{
	display: none !important;
}
.hidden-xs{
	display: block !important;
}
.header-login-col .hidden-xs{
	display: inline-block !important;
}
@media(max-width: 1200px){
	html,body{
		font-size: 12px;
	}
	.all-games-sec .container:nth-child(3) {
    width: 650px;
    max-width: 100%;
}
}
@media(max-width: 992px){
	html,body{
		font-size: 9px;
	}
	.all-games-sec .container:nth-child(3) {
    width: 550px;
    max-width: 100%;
}
.slick-dots li button{
	width: 6px !important;
	height: 6px !important
}
.navbar-nav{
	padding-top: 2rem;
}
.navbar-light .navbar-nav .nav-link{
	margin:.5rem 0;
}
.header-login-col{
	padding-top: .5rem;
	padding-bottom: 1rem;
}
.navbar-light .navbar-toggler{
		float: left;
	}
	.navbar{
		display: inline-block;
		width: 100%;
		float: left;
		padding: 1rem 2rem;
	}
	.msg-img{
		float: right;
		width: 3rem;
		margin-top: .7rem;
	}
	.logo {
    float: left;
    margin-left: calc(50% - 9.1rem);
    margin-top: .5rem;
}
.navbar-toggler-icon {
    display: inline-block;
    width: 2.5em;
    height: 2.5em;
}
.navbar-toggler-icon svg path{
	stroke: #FF006C
}
.collapse.show, .collapsing {
    display: inline-block;
    width: 100%;
    padding-left: 1rem;
}
.logo1{
	display: none;
}
.logo2{
	display: block;
}
.visible-xs.msg-img{
    display: block !important;
}
}
@media(max-width: 767px){
	html,body{
		font-size: 10px;
	}
	.visible-xs{
		display: block !important;
	}
	.hidden-xs{
		display: none !important;
	}
	.header-login-col .hidden-xs{
	display: none !important;
}
	.navbar-light .navbar-toggler{
		float: left;
	}
	.navbar{
		display: inline-block;
		width: 100%;
		float: left;
		padding: 1rem 2rem;
	}
	.msg-img{
		float: right;
		width: 3rem;
		margin-top: .7rem;
	}
	.logo {
    float: left;
    margin-left: calc(50% - 9.1rem);
    margin-top: .5rem;
}
.navbar-toggler-icon {
    display: inline-block;
    width: 2.5em;
    height: 2.5em;
}
.navbar-toggler-icon svg path{
	stroke: #FF006C
}
.collapse.show, .collapsing {
    display: inline-block;
    width: 100%;
    padding-left: 1rem;
}
.search-sec .col-12{
	-webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% + 20rem);
    flex: 0 0 calc(100% + 20rem);
    max-width: calc(100% + 20rem);
    min-width: 58rem;
}
.search-sec .col-12.active{
	margin-left: calc(-50% - 9rem);
}
.fa-caret-right{
	display: block;
}
.search-sec ul{
	width: calc(100% - 145px );
}
.search-input{
	padding-right: 2rem;
}
.all-games-sec .all-games-col{
	margin-bottom: 6rem;
}
.all-games-sec .container:nth-child(3) h3{
	display: inline-block;
	font-size: 2rem;
}
.all-games-sec .container:nth-child(3) h3 img {
    width: 4rem;
    float: left;
    margin-top: -.6rem;
    margin-right: 1rem;
}
.all-games-sec .container:nth-child(2){
	padding-bottom: 0;
}
.all-games-sec .container:nth-child(3) p{
	text-align: center;
}
.all-games-sec .container:nth-child(3) p span{
	display: inline-block;
	margin-top: 1rem;
}
.all-games-sec .container:nth-child(3){
	margin-top: -3rem;
}
.feature-sec{
	background-image: url(/img/mb-bg.png);
}
.all-games-sec {
    padding-bottom: 2rem;
}
.feature-block{
width: 100%;
float: left;
padding-bottom: 3rem;
}
.feature-sec .slick-slide img {
    height: 14rem;
}
.feature-sec .slick-slide h6 {
    font-size: 1.55rem;
    padding-top: .5rem;
}
.feature-sec .slick-slide p {
    font-size: 1.2rem;
}
.footer-menu-col{
	padding: 0 4rem;
	padding-bottom: 4rem;
}
.footer-menu-col ul br{
	display: block;
}
.footer-menu-col ul li:nth-child(7){
	margin-left: 0
}
.footer h5 {
    font-size: 1.4rem;
}
.footer-menu-col ul li img{
	margin-bottom: 1.4rem;
	width: 4rem;
}
.footer-menu-col:after{
	top: initial;
	left: 4rem;
	right: 4rem;
	width: initial;
	height: .2rem;
	bottom: 2rem
}
.footer-logo-col{
	text-align: center;
}
.footer-logo-col .footer-logo-col-left {
        width: 9rem;
    float: none;
    display: inline-block;
    vertical-align: top;
    padding-right: .8rem;
}
.footer-logo-col .footer-logo-col-right {
    width: 12rem;
    float: none;
    display: inline-block;
    padding-left: 1rem;
    text-align: left;
}
.footer-logo-col .footer-logo-col-right h6 {
    font-size: 1rem;
    margin-top: 1.2rem;
        color: #D8D8D8;
}
.footer-logo-col .footer-logo-col-right h5:nth-child(4){
	margin-top: 1.5rem;
}
.plus-col p img, .plus-col:after{
	display: none;
}
.plus-col p span{
	width: 100%;
	padding-left: 0;
}
.plus-col{
	padding-top: 2rem;
}
.plus-col p br{
	display: none;
}
.plus-col p b{
	display: none;
}
.plus-col p{
	font-size: .9rem
}
.plus-col {
    padding-right: 15px;
}
.bottom-footer .plus1-img{
 width: 4rem;
 float: left;
 margin: .5rem
}
.footer-logo1 {
    width: 10rem;
    margin-top: .5rem;
}
.bottom-footer p {
    font-size: .9rem;
}
.footer{
	padding-bottom: 5rem;
}
.game-area-col.game-area-col-right{
	padding-left: 15px;
}
.game-area-header{
	padding:0;
}
.game-area-col.game-area-col-right .game-area-header{
	border:0;
}
.game-area-col.game-area-col-right .game-area-header p{
	display: none;
}
.game-area-col.game-area-col-right .game-area-header h6{
	width: 100%;
	text-align: center;
	float: none;
	color: #CD0955;
	font-size: 1.4rem;
	font-weight: 600;
}
.prediction-col-inner button {
    width: 100%;
    border-radius: 10px;
    border: 0;
    background: linear-gradient(#353535, #252525);
    color: #8A8A8A;
    font-weight: 600;
    font-size: 1.2rem;
    height: 3.2rem;
    cursor: pointer;
}
.prediction-number, .lucky-number {
    border-radius: 10px;
}
.prediction-col-inner button.roll-under:after {
    right: -1.5rem;
}
.payout-col ul li {
    font-size: 1.8rem;
}
.payout-col ul li span {
    color: #8A8A8A;
    font-size: .8rem;
}
.payout-col ul li p {
    line-height: 1.2;
}
.total-col1 .total-col-inner2{
	display: none;
}
.total-col1 .total-col-inner1, .total-col1 .total-col-inner3{
	width: 47%;
	float: left;
}
.total-col1 .total-col-inner3{
	float: right;
}
.total-col1 .total-col-inner1 .bet-col {
    width: calc(100% - 2.8rem);
}
.total-col1 .total-col-inner1 img {
    width: 2.8rem;
    margin-top: 0.9rem;
}
.total-col1 .total-col-inner1 .bet-col p {
    font-size: 1rem;
    color: #8A8A8A;
}
.total-col1 .total-col-inner1 .bet-col p span {
    font-size: 1.9rem;
    font-weight: 600;
}
.payout-col, .total-col1 .total-col-inner1, .total-col1 .total-col-inner3{
	border-radius: 10px;
}
.total-col-inner3 {
    min-height: 5.54rem;
}
.total-col1 .total-col-inner3 p {
    padding-top: 1.2rem;
    font-size: 1.6rem;
}
.total-col1-xs .total-col-inner1{
	background: none;
	padding:0;
}
.total-col1-xs .total-col-inner1 .x-xol{
	width: 100%;
}
.total-col1 .total-col-inner1 .x-xol div:last-child{
	margin-right: 0;
}
.total-col1 .total-col-inner1 .x-xol div{
	width: 25%;
	margin-right: 12%;
	    height: 2.9rem;
    line-height: 2.9rem;
    font-size: 1rem;
    border-radius: 6px;
}
.total-col1-xs{
	padding-top: 0;
}
.total-col {
    padding-top: 1.5rem;
}
.total-col.total-col1-xs{
	padding-top: 0;
}
.total-col1.total-col1-xs .total-col-inner2{
	display: flex;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
	-o-justify-content: center;
	width: 47%;
	text-align: center;
	float: right;
}
.switch{
	transform: rotate(-90deg);
}
.switch--vertical {
    width: 1.8rem;
    height: 4.6rem;
}
.total-col1 .total-col-inner2 p {
    color: #fff;
    font-size: 1rem;
    padding-top: 9px;
    margin-left: 3rem;
}
.switch input:checked, .switch--vertical input, .switch--vertical .toggle-outside {
    width: 1.8rem;
}
.switch .toggle-outside{
	padding:0;
}
.switch--vertical input:checked ~ .toggle-outside .toggle-inside {
    width: 1.8rem;
    height: 1.8rem;
    left: 0;
    top: 0;
    background: #5A5A5A;
}
.switch--vertical input ~ input:checked ~ .toggle-outside .toggle-inside{
	top: 2.45rem;
}
.switch {
    margin: -6px 0px !important;
}
.dashboard-sec{
	padding-bottom: 3rem;
}
.dashboard-table-sec .game-area-header h6{
	display: none;
}
.dashboard-table-sec{
	padding-top: 1rem;
	border-top: 0;
}
.game-area-header p {
    font-size: 1rem;
}
.dashboard-table-sec-tb thead th {
    padding: 1.5rem 1rem;
    padding-bottom: .7rem;
    font-size: .8rem;
    font-weight: 500;
}
.dashboard-table-sec-tb tbody td {
    padding: .5rem 1rem;
    font-size: .8rem;
}
.dashboard-page .footer{
	display: none;
}
}