body, html{
	margin:0;
	font-size: 16px;
	font-family: 'Montserrat', sans-serif;
}
p, h1, h2, h3, h4, h5, h6{
	margin:0;
	padding:0;
}
ul,ol{
	list-style: none;
	margin:0;
	padding:0;
}
button:focus, input:focus, textarea:focus{
	outline: none;
}
.navbar{
background-color: #171717;
padding: 1rem 1rem;
}
.logo{
width: 11.5rem;
margin-right: .9rem;
margin-left: .2rem;
}
.navbar-light .navbar-nav .nav-link {
    color: #ADADAD;
font-size: .9rem;
font-weight: 400;
margin: 0 .7rem;
}
.navbar-light .navbar-nav .show > .nav-link, 
.navbar-light .navbar-nav .active > .nav-link, 
.navbar-nav .nav-link:hover, 
.navbar-light .navbar-nav .nav-link.show, 
.navbar-light .navbar-nav .nav-link.active {
    color: #FFC34B !important;
}
.header-login-col img{
width: 2rem;
margin-right: 2rem;
cursor: pointer;
}
.header-login-col button{
background-color: transparent;
color: #fff;
border: 1px solid #F30168;
font-size: .8rem;
color: #ADADAD;
border-radius: .3rem;
padding: 2px 2rem;
cursor: pointer;
margin-right: 15rem;
transition: all .3s ease;
}
.header-login-col button:hover{
	background: #F30168;
	color: #fff;
}
.header-login-col{
display: inline-block;
}
.carousel-item-left span{
	background-image: url(/img/img1.png);
}
.carousel-item-left {
color: #fff;
padding-top: 4rem;
padding-bottom: 4rem;
}
.carousel-item-right img{
margin-bottom: 1rem;
margin-top: 2rem;
}
img{
max-width: 100%
}
.carousel-item-left span{
background-size: 100% 100%;
padding: 0 1rem;
display: inline-block;
margin-left: -.4rem;
}
.carousel-item-left h1{
font-size: 3.7rem;
line-height: 1.1;
}
.carousel-item-left h2{
font-size: 2.65rem;
line-height: 1.1;
}
.carousel-item-left p{
font-size: 1.4rem;
margin-top: 1.3rem;
}
.carousel-indicators li{
width: 8px;
height: 8px;
border-radius: 50%;
margin: 0 3px;
cursor: pointer;
}
.slick-list {
	padding: 0 !important;
}
.carousel-item-left{
	padding-left: 3rem;
}
.carousel-item-left,
.carousel-item-right{
width: 50%;
display: inline-block;
float: left;
}
.carousel-item-row{
display: inline-block;
width: 100%;
}
.carousel-item-right img{
width: 100%;
}
.slick-prev, .slick-next{
display: none !important;
}
.slick-dots li button{
padding:0 !important;
margin:0 !important;
height: 8px !important;
width: 8px !important;
background-color: #fff !important;
border-radius: 50% !important;
}
.slick-dots li button:before{
display: none !important;
}
.slick-dots li{
width: initial !important;
height: initial !important;
opacity: .5 !important;
}
.slick-dots li.slick-active{
opacity: 1 !important;
}
.slick-dotted.slick-slider{
margin-bottom: 0 !important;
}

.header-login-col button:hover {
  background: #F30168;
  color: #fff;
}

.header-login-col {
  display: inline-block;

}
.slick-dots{
bottom: 7px !important;
}
.search-sec {
    background-color: #272727;
    position: relative;
}
@media (max-width: 767px) {
  .body-wrapper {
      padding-top: 5.5rem;
  }
  .logo {
      margin-left: calc(50% - 10.1rem) !important;
  }

}.search-sec ul{
float: left;
padding-top: .2rem;
}
.search-sec ul li{
display: inline-block;
color: #6D6D6D;
font-size: .9rem;
width: 5rem;
margin: 0 .5rem;
cursor: pointer;
position: relative;
float: left;
padding-bottom: .8rem;
}
.search-sec ul li.active:after{
position: absolute;
content: "";
left:0;
right:0;
height: .3rem;
bottom: 0;
background-color: #C1F801;
}
.search-sec ul li div{
width: 100%;
height: 3rem;
}
.search-sec ul li div img{
height: 1.8rem;
}
.search-sec ul li p{
font-size: .7rem;
width: 100%;
text-align: center;
font-weight: 600;
}
.search-sec ul li:nth-child(1){
	margin-left: 0;
}
.search-sec ul li:nth-child(3) div img{
height: 2.5rem;
}
.search-sec ul li.active{
color: #fff;
}
.search-input{
float: right;
width: 14rem;
margin-top: .8rem;
}
.search-input .search-input-col{
width: 100%;
position: relative;
}
.search-input .search-input-col input{
background-color: #363636;
border: 1px solid #464646;
border-radius: .4rem;
width: 100%;
color: #fff;
padding: .2rem 1rem;
font-size: .8rem;
}
.hero-sec {
  background-image: url(/img/bg.png);
  background-position: 50%;
  background-size: cover;

}
.search-input .search-input-col img{
position: absolute;
top:.25rem;
right:.6rem;
width: 1.2rem;
}
.search-input p{
text-align: center;
font-size: .7rem;
    color: #6D6D6D;
font-weight: 600;
padding-top: .4rem;
}
.all-games-sec{
background-color: #171717;
color: #fff;
padding-top: 1.5rem;
padding-bottom: 1rem;
}
.all-games-sec h1 img{
width: 3.5rem;
margin-left: .7rem;
margin-right: .6rem;
margin-top: -.4rem;
}
.all-games-sec h1 {
font-size: 1.35rem;
}
.all-games-sec .container:nth-child(2){
padding-top: 5rem;
padding-bottom: 4rem;
}
.all-games-sec .all-games-col{
width: 100%;
border-radius: .5rem;
}
.all-games-sec .all-games-col1 .all-games-col-inner{
background-color: #00AEEF;
}
.all-games-sec .all-games-col1 .all-games-col-inner1{
background-color: #004A80;
}
.all-games-sec .all-games-col2 .all-games-col-inner{
background-color: #C6DE0F;
}
.all-games-sec .all-games-col2 .all-games-col-inner1{
background-color: #6C790A;
}
.all-games-sec .all-games-col3 .all-games-col-inner{
background-color: #FFCC00;
}
.all-games-sec .all-games-col3 .all-games-col-inner1{
background-color: #A3620A;
}
.all-games-sec .all-games-col4 .all-games-col-inner{
background-color: #8560A8;
}
.all-games-sec .all-games-col4 .all-games-col-inner1{
background-color: #440E62;
}
.all-games-sec .all-games-col5 .all-games-col-inner {
  background-color: #959595;
}

.all-games-sec .all-games-col5 .all-games-col-inner1 {
  background-color: #252525;
}

.all-games-sec .all-games-col6 .all-games-col-inner {
  background-color: rgb(158, 0, 57);
}

.all-games-sec .all-games-col6 .all-games-col-inner1 {
  background-color: rgb(85, 0, 27);
}

.all-games-sec .all-games-col7 .all-games-col-inner {
  background-color: rgb(25, 123, 48);
}

.all-games-sec .all-games-col1 .all-games-col-inner {
  background-color: #00AEEF;
}

.all-games-sec .all-games-col1 .all-games-col-inner1 {
  background-color: #004A80;
}

.all-games-sec .all-games-col2 .all-games-col-inner {
  background-color: #C6DE0F;
}

.all-games-sec .all-games-col2 .all-games-col-inner1 {
  background-color: #6C790A;
}

.all-games-sec .all-games-col3 .all-games-col-inner {
  background-color: #FFCC00;
}

.all-games-sec .all-games-col3 .all-games-col-inner1 {
  background-color: #A3620A;
}

.all-games-sec .all-games-col4 .all-games-col-inner {
  background-color: #8560A8;
}

.all-games-sec .all-games-col4 .all-games-col-inner1 {
  background-color: #440E62;
}

.all-games-sec .all-games-col5 .all-games-col-inner {
  background-color: #959595;
}

.all-games-sec .all-games-col5 .all-games-col-inner1 {
  background-color: #252525;
}

.all-games-sec .all-games-col6 .all-games-col-inner {
  background-color: rgb(158, 0, 57);
}

.all-games-sec .all-games-col6 .all-games-col-inner1 {
  background-color: rgb(85, 0, 27);
}

.all-games-sec .all-games-col7 .all-games-col-inner {
  background-color: rgb(25, 123, 48);
}

.all-games-sec .all-games-col7 .all-games-col-inner1 {
  background-color: rgb(0, 50, 21);
}

.all-games-sec .all-games-col8 .all-games-col-inner {
  background-color: ghostwhite;
}

.all-games-sec .all-games-col8 .all-games-col-inner1 {
  background-color: rgb(70, 70, 70);
}

.all-games-sec .all-games-col9 .all-games-col-inner {
  background-color: rgb(54, 54, 54);
}
.all-games-sec .all-games-col7 .all-games-col-inner1 {
  background-color: rgb(0, 50, 21);
}

.all-games-sec .all-games-col8 .all-games-col-inner {
  background-color: ghostwhite;
}

.all-games-sec .all-games-col8 .all-games-col-inner1 {
  background-color: rgb(70, 70, 70);
}

.all-games-sec .all-games-col9 .all-games-col-inner {
  background-color: rgb(54, 54, 54);
}

.all-games-sec .all-games-col9 .all-games-col-inner1 {
  background-color: rgb(69, 0, 0);
}
.all-games-sec .all-games-col .all-games-col-inner {
    padding: 0 1.8rem;
    position: relative;
text-align: center;
    border-top-left-radius: .4rem;
    border-top-right-radius: .4rem;
}
.all-games-sec .all-games-col .all-games-col-inner img{
position: relative;
z-index:2;
}
.all-games-sec .all-games-col .all-games-col-inner img:nth-child(1){
height: 9.5rem;
margin-top: -3.6rem;
margin-bottom: .5rem;
}
.all-games-sec .all-games-col .all-games-col-inner .dots-img{
position: absolute;
left: 1.8rem;
right: 1.8rem;
top: 1rem;
width: calc(100% - 3.6rem);
z-index:1;
}
.all-games-col-inner1{
 border-bottom-left-radius: .4rem;
    border-bottom-right-radius: .4rem;
display: inline-block;
width: 100%;
padding: .3rem .8rem;
}
.all-games-col-inner1 img{
width: 5.5rem;
float: left;
}
.all-games-col-inner1 p{
font-size: .85rem;
float: right;
padding-right: .4rem;
padding-top: .2rem;
font-weight: 500;
}
.all-games-col1 .all-games-col-inner1 p{
color: #80A5C0;
}
.all-games-col2 .all-games-col-inner1 p{
color: #A7AF6C;
}
.all-games-col3 .all-games-col-inner1 p{
color: #C8A16C;
}
.all-games-col4 .all-games-col-inner1 p{
color: #A287B1;
}
.all-games-sec .container:nth-child(3) h3{
color: #EED996;
font-size: 1.7rem;
font-weight: 600;
padding-top: 1rem;
}
.all-games-sec .container:nth-child(3) p{
color: #898888;
font-size: .71rem;
font-weight: 600;
padding-top: 1rem;
line-height: 1.8;
}
.feature-sec{
	background-image: url(/img/bg1.png);
}
.feature-sec {
    background-color: #171717;
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
color: #fff;
text-align: center;
padding-top: 10rem;
}
.feature-sec .slick-slide{
text-align: center;
}
.feature-sec .slick-slide img{
height: 7rem;
float: none;
display: block;
    margin: auto;
}
.feature-sec .slick-slide h6{
font-size: .95rem;
padding-top: .5rem;
}
.feature-sec .slick-slide p{
font-size: .7rem;
color: #898888;
font-weight: 600;
padding-top: .5rem;
}
.center1 .slick-dots{
top: -4rem !important;
bottom: initial !important;
}
.center1 .slick-dots li button{
background-color: #5a5959 !important; 
opacity: 1 !important;
}
.center2{
padding-top: 3rem;
padding-bottom: 4rem;
}
.center2 .slick-dots{
bottom: 1.5rem !important;
}
.center2 .slick-dots li button{
background-color: #5a5959 !important; 
opacity: 1 !important;
}
.footer{
background-color: #171717;
color: #fff;
padding-top: 4rem;
padding-bottom: 3rem;
}
.footer-menu-col ul{
padding-top: 1rem;
}
.footer-menu-col ul li{
display: inline-block;
margin: 0 .7rem;
}
.footer-menu-col ul li img{
width: 3.5rem;
}
.footer-menu-col ul li:first-child{
margin-left: 0;
}
.footer h5{
font-size: 1.2rem;
}
.footer hr{
border-top: .2rem solid #363636;
margin: 2.5rem 0;
}
.footer-logo-col{
padding-left:0;
}
.footer-logo-col .footer-logo-col-left{
width: 25%;
float: left;
}
.footer-logo-col .footer-logo-col-right{
width: 75%;
float: left;
padding-left: 1rem;
}
.footer-logo-col .footer-logo-col-right h6{
font-size: .8rem;
margin-top: 1.2rem;
}
.footer-logo-col .footer-logo-col-right a{
color: #D8D8D8;
}
.footer-logo-col .footer-logo-col-right h6 img{
width: 1rem;
margin-right: .5rem;
}
.footer-menu-col, .plus-col{
position: relative;
}
.footer-menu-col:after, .plus-col:after{
position: absolute;
content:"";
width: .14rem;
top:0;
bottom:0;
right: 2.5rem;
background-color: #363636;
}
.plus-col p img{
width: 3.6rem;
float: left;
margin-top: .2rem;
}
.plus-col p span{
display: inline-block;
width: calc(100% - 4rem);
float: left;
padding-left: 1rem;
}
.plus-col p{
color: #5A5A5A;
font-size: .6rem;
font-weight: 600;
line-height: 1.8;
margin-bottom: .7rem;
display: inline-block;
}
.plus-col p:last-child{
margin-bottom: 0;
}
.plus-col{
padding-right: 4.5rem;
}
.resources-col{
padding-left: 0;
}
.resources-col li a{
color: #D8D8D8;
font-size: .75rem;
font-weight: 500;
}
.resources-col li{
margin-top: 1rem;
line-height: 1;
}
.resources-col h5{
line-height: .7;
}
.bottom-footer{
background-color : #111111;
text-align: center;
padding: .8rem 0;
}
.footer-logo1{
width: 6rem;
}
.bottom-footer p{
color: #454545;
font-size: .6rem;
font-weight: 600;
padding-top: .5rem;
}
.fa-caret-right, .fa-caret-left {
    color: #fff;
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    top: 0;
    width: 4rem;
    text-align: center;
    line-height: 50px;
    left: inherit;
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
    background: linear-gradient(to left, rgb(39, 39, 39), rgba(39, 39, 39, 0));
}
.fa-caret-left{
	left: 0rem;
	right: inherit;
	background: linear-gradient(to right, rgb(39, 39, 39), rgba(39, 39, 39, 0));
}
.search-sec {
    overflow: hidden;
    width: 100%;
}
.feature-block{
width: 33.3%;
float: left;
}
.footer-menu-col ul br{
	display: none;
}
.logo2{
	display: none;
}
.dashboard-sec{
background-color: #111111;
color: #fff;
padding: 15px 0;
}
.game-area-header{
border-bottom: 1px solid #8A8A8A;
padding: 0 .7rem;
padding-top: .7rem
}
.game-area-header p{
color: #9A9A9A;
font-weight: 600;
font-size: .8rem;
display: inline-block;
}
.game-area-header h6{
color: #CCE405;
font-weight: 600;
font-size: .9rem;
display: inline-block;
float: right;
font-weight: 500;
cursor: pointer;
}
.game-area-col{
border-right: 1px solid #262626;
padding-right: 3rem;
}
.game-area-header h6 i{
color: #fff;
font-size: 1.2rem;
vertical-align: middle;
margin-top: -.2rem;
margin-left: .5rem;

}
.jackpot-col {
    padding: 1.5rem 0;
    display: flex;
    padding-bottom: 1rem;
}
.jackpot-col .jackpot-inner-col:nth-child(1){
width: 40%;
display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}
.jackpot-col .jackpot-inner-col:nth-child(2){
width: 23%;
}
.jackpot-col .jackpot-inner-col:nth-child(3){
width: 23%;
}
.jackpot-col .jackpot-inner-col:nth-child(4){
width: 14%;
}
.jackpot-col .jackpot-inner-col:nth-child(4){
padding-right:0;
}
.jackpot-col .jackpot-inner-col:nth-child(4) p{
float: right;
width: initial;
}
.jackpot-col .jackpot-inner-col b {
    font-weight: 600;
    display: inline-block;
    color: #fff !important;
    font-size: 1.25rem;
    line-height: .9;
}
.jackpot-col .jackpot-inner-col:nth-child(1) b{
font-size: .9rem;
}
.jackpot-col .jackpot-inner-col img{
width: 3rem;
}
.jackpot-col .jackpot-inner-col .fa-question-circle-o{
color: #5D5D5D;
vertical-align: top;
cursor: pointer;
}
.jackpot-col .jackpot-inner-col .fa-question-circle-o:hover{
color: #fff;
}
.jackpot-col .jackpot-inner-col{

position: relative;
padding-right: .7rem;
}
.jackpot-col .jackpot-inner-col p{
color: #F2E167;
width: 100%;
font-weight: 600;
font-size: .8rem;
text-align: center;
}
.jackpot-col .jackpot-inner-col p span{
color: #fff;
font-size: .6rem;
}
.jackpot-col .jackpot-inner-col div:nth-child(2){
padding-left: .5rem;
}
.jackpot-col .jackpot-inner-col h6{
width: 100%;
font-weight: 600;
font-size: .9rem;
text-align: center;
}
.jackpot-col .jackpot-inner-col .fa-angle-right{
color: #fff;
float: right;
vertical-align: middle;
position: absolute;
top: .5rem;
right:0;
font-size: 1.4rem;
color: #6F6F6F;
}
.game-area-col.game-area-col-right {
    border-right: 0;
    padding-left: 3rem;
padding-right: 15px;
}
.competitors-col .competitors--inner, .competitors-col .competitors--inner1{
width: 50%;
display: inline-block;
}
.competitors-col {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    border-top: 2px solid #262626;
    padding-top: 1rem;
}
.competitors-col .competitors--inner h1{
color: #868686;
font-size: .85rem;
padding: .2rem .8rem;
}
.competitors-col .competitors--inner h1 span{
color: #fff;
font-size: .5rem;
float: right;
text-align: center;
}
.competitors-col .competitors--inner ul{
margin-top: .5rem;
}
.competitors-col .competitors--inner ul li{
padding: .2rem .8rem;
font-size: .8rem;
background-color: #181818;
font-weight: 500;
}
.competitors-col .competitors--inner ul li:nth-child(even){
background-color: transparent;
}
.competitors-col .competitors--inner ul li p span{
float: right;
}
.competitors-col .competitors--inner ul li.green{
color: #B0FF2C;
}
.competitors-col .competitors--inner ul li.orange{
color: #FCBF28;
}
.competitors-col .competitors--inner ul li.red{
color: #E8025D;
}
.competitors-col .competitors--inner ul li.blue{
color: #2CAEED;
}
.competitors-col .competitors--inner ul li.purple{
color: #9600FC;
}
.competitors-col .competitors--inner ul li.grey{
color: #868686;
}
.competitors-col .competitors--inner ul li.empty{
min-height: 1.6rem;
}
.competitors-col .competitors--inner ul li:last-child{
border-bottom: 1px solid #5A5A5A;
}
.competitors-chart-col{
overflow: hidden;
position: relative;
}
#myChart{
width: 26.5rem !important;
height: 13rem !important;
margin-left: -5rem;
position: relative;
z-index:1;
}
.competitors-chart-col-text{
position: absolute;
top:0;
left:0;
right:0;
bottom:0;
text-align: center;
padding-top: 2.6rem;
padding-left: .6rem;
}
.competitors-chart-col-text p{
color: #5A5A5A;
font-weight: 600;
font-size: .8rem;
}
.competitors-chart-col-text h4{
font-size: 1.3rem;
margin-top: .5rem;
}
.competitors-chart-col-text h1{
font-size: 2.3rem;
}
.competitors-chart-col-text hr{
margin: .7rem 0;
border-top: 1px solid #5A5A5A;
width: 70%;
margin-left: auto;
margin-right: auto;
}
.pool-col {
    display: inline-block;
    width: 95%;
    margin-left: 5%;
    border: 2px dashed #E8025D;
    margin-top: 1.2rem;
    padding: .3rem 0;
    border-radius: 4px;
}
.pool-col p{
width: 50%;
float: left;
text-align: center;
border-right: 2px solid #5A5A5A;
font-weight: 600;
font-size: .9rem;
line-height: 1;
padding: .25rem 0;
}
.pool-col p span{
font-size: .5rem;
color: #E8025D;
}
.pool-col p:last-child{
border-right: 0;
}
.payout-col{
background: linear-gradient(#353535, #252525);
border-radius: .4rem;
margin-top: .5rem;
display: inline-block;
width: 100%;
padding: .75rem 1rem;
}
.payout-col ul{
display: inline-block;
width: 100%;
text-align: center;
float: left;
}
.payout-col ul li p{
line-height: 1.4;
}
.plus-col p span b{
    font-weight: 600;
}
.payout-col ul li {
display: inline-block;
width: 33.3333%;
float: left;
font-weight: 600;
font-size: .9rem;
border-right: 2px solid #757575;
float: left;
}
.payout-col ul li:last-child{
border:0;
}
.payout-col ul li b{
font-weight: 700;
color: #8A8A8A;
}
.payout-col ul li span{
color: #8A8A8A;
font-size: .6rem;
}
.total-col{
display: inline-block;
width: 100%;
padding-top: .5rem;
padding-bottom: 1rem;
}
.total-col-inner{
width: 33.3333%;
float: left;
display: inline-block;
text-align: center;
}
.total-col-inner1{
padding-left: 1rem;
}
.total-col-inner2{
width: 32.3333%;
}
.total-col-inner3{
width: 34.3333%;
}
.total-col-inner p{
font-size: .9rem;
font-weight: 600;
color: #CBE42B;
}
.total-col-inner2 p{
color: #E8025D;
}
.total-col-inner3 p{
color: #252525;
line-height: 1.2;
}
.total-col-inner1, .total-col-inner2{
padding-top: .4rem;
}
.total-col-inner3{
background-color: #CBE42B;
border-radius: 4px;
padding: .7rem 0;
cursor: pointer;
}
.total-col-inner p span{
font-size: .6rem;
font-weight: 500;
color: #fff;
}
.prediction-col{
width: 100%;
display: inline-block;
padding: 0 15px;
border-top: 2px solid #262626;
    padding-top: 1rem;
}
.prediction-col .prediction-number {
    background: linear-gradient(#353535, #252525);
}
.prediction-col .prediction-col-inner{
width: 50%;
display: inline-block;
float: left;
}
.prediction-col-inner1{
padding-right: 15px;
}
.prediction-col-inner2{
padding-left: 15px;
}
.prediction-number, .lucky-number{
border-radius: 6px;
text-align: center;
font-size: .9rem;
font-weight: 600;
color: #8A8A8A;
min-height: 10.7rem;
margin-bottom: 20px;
padding-top: 1rem;
}
.prediction-number span, .lucky-number span{
font-size: 6rem;
font-weight: 600;
color: #fff;
}
.lucky-number{
color: #fff;
}
.lucky-number span{
color: #CBE42B;
}
.prediction-col-inner button{
width: 100%;
border-radius: 6px;
border:0;
background: linear-gradient(#353535, #252525);
color: #8A8A8A;
font-weight: 600;
font-size: 1.1rem;
height: 2.8rem;
cursor: pointer;
}
.prediction-col-inner button.roll-under{
position: relative;
}
.prediction-col-inner button.roll-under:after{
position: absolute;
content: "";
top: .3rem;
bottom: .3rem;
right: -1rem;
width: 2px;
background: linear-gradient(#353535, #252525);
}
.prediction-col-inner button.active{
color: #CBE43C;
}
.hir-labels, .html-input-range-custom .hir-tracker-bg::before, .html-input-range-custom .hir-tracker-bg::after, .html-inupt-range .tooltip::before, .html-inupt-range .tooltip::after{
display: none;
}
.html-input-range-custom .hir-tracker-bg{
width: calc(100% - 20px);
left:10px !important;
border-radius: 45px;
}
#html-input-range {
    width: calc(100% - -27px);
    left: -10px;
    height: 12px;
    top: 5px;
}
.html-input-range-custom .hir-tracker-thumb{
border-radius: 45px;
left:10px !important;
}
.html-inupt-range .tooltip{
z-index: 999;
opacity: 1;
}
.html-input-range-custom .hir-tracker-thumb::after{
border: 4px solid #CBE43C;
background-color: transparent;
width: 28px;
height: 28px;
top: -8px;
z-index: 9;
}
.html-input-range-custom .hir-tracker-thumb, .html-input-range-custom .hir-tracker-bg{
height: 12px;
}
.html-input-range-custom .hir-tracker-thumb{
background-color: #CBE43C;
transition: none !important;
}
.html-input-range-custom .hir-tracker-bg{
background-color: #CD0955;
}
.html-inupt-range .tooltip {
    font-weight: 600;
    padding: 0;
    top: 5px;
    transition: none !important;
    z-index: 0;
    background-color: #000;
    text-align: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin-left: 4px;
    font-size: 13px;
}
.slider.html-inupt-range {
    width: 94%;
    margin: 1% 3%;
    height: 32px;
}
.start-number, .end-number {
    position: absolute;
    font-size: 14px;
    font-weight: 500;
    left: -5px;
    top: 5px;
}
.end-number {
left: initial;
right: -15px;
}
/* mixin */
.total-col-inner3{
min-height: 3.54rem;
}
.total-col1 .total-col-inner3 p{
padding-top: .6rem;
}
.total-col1 .total-col-inner1 {
    width: 58.3333%;
background: linear-gradient(#353535, #252525);
    border-radius: .4rem;
padding:0;
min-height: 2.6rem;
padding: .7rem .5rem;
}
.total-col1 .total-col-inner2 {
    width: 7.3333%;
}
.total-col1 .total-col-inner1 img{
width: 1.8rem;
float: left;
display: inline-block;
margin-top: .1rem;
}
.total-col1 .total-col-inner1 .bet-col{
width: calc(60% - 1.8rem);
float: left;
display: inline-block;
}
.total-col1 .total-col-inner1 .x-xol{
width: 40%;
float: left;
display: inline-block;
}
.total-col1 .total-col-inner1 .x-xol div {
    width: 29%;
    float: left;
    font-size: .6rem;
    background-color: #5A5A5A;
    margin-right: 3.5%;
    border-radius: 2px;
    height: 1.9rem;
    line-height: 1.9rem;
    margin-top: 3px;
    cursor: pointer;
}
.total-col1 .total-col-inner1 .bet-col p span{
font-size: .9rem;
font-weight: 600;
}
.total-col1 .total-col-inner1 .bet-col p{
font-size: .6rem;
color: #8A8A8A;
}
.switch--vertical{
width: 1.2rem;
height: 2.6rem;
}
.switch input:checked, .switch--vertical input, .switch--vertical .toggle-outside{
width: 1.2rem;
}
.switch--vertical input:checked ~ .toggle-outside .toggle-inside{
width: 1.2rem;
height: 1.2rem;
left: 0;
top: 0;
background: #5A5A5A;
}
.switch{
    margin:0 9px;
}
.total-col1 .total-col-inner2{
    padding-top: 0;
}
.total-col1 .total-col-inner2 p{
color: #fff;
font-size: .6rem;
padding-top: 2px;
}
.dashboard-table-sec{
background-color: #171717;
border-bottom: 1px solid #363636;
padding-bottom: 1rem;
}
.dashboard-table-sec .game-area-header{
padding-left:0;
}
.dashboard-table-sec .game-area-header p span{
display: inline-block;
padding: 0rem 1rem;
padding-bottom: 1rem;
color: #fff;
cursor: pointer;
border-bottom: 2px solid transparent;
transition: all .3s ease;
}
.dashboard-table-sec .game-area-header p span:hover, .dashboard-table-sec .game-area-header p span.active{
border-bottom: 2px solid #CBE43C;
}
.dashboard-table-sec .game-area-header h6{
padding-top: .5rem;
}
.dashboard-table-sec-tb{
padding-top: .4rem;
}
.dashboard-table-sec-tb td{
color: #A9A9A9;
}
.dashboard-table-sec-tb th{
color: #fff;
}
.dashboard-table-sec-tb thead{
background: linear-gradient(to bottom, #262626, #535353);
}
.dashboard-table-sec-tb thead th{
padding: 2.5rem 2rem;
padding-bottom: .7rem;
font-size: .8rem;
font-weight: 500;
}
.dashboard-table-sec-tb tbody td{
padding: .5rem 2rem;
font-size: .8rem;
}
.dashboard-table-sec-tb tbody td.green{
color: #CBE42B;
}
.dashboard-table-sec-tb tbody td.red{
color: #CD0955;
}
.dashboard-table-sec-tb tbody tr:nth-child(odd){
background-color: #252525;
}
@media(min-width: 1200px){
.all-games-sec .container:nth-child(2){
width: 1200px;
max-width: 100%;
}
.all-games-sec .container:nth-child(3){
width: 880px;
max-width: 100%;
}
.feature-sec .container{
width: 1250px;
max-width: 100%;
}
}
.games-row { margin-top: 4rem; }
.all-games-col { transition: .2s; }
.all-games-col:hover {
  transform: scale(1.10);
}
.all-games-col:after {
  animation: shine 3s ease-in-out infinite;
  animation-fill-mode: forwards;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 0;
  transform: rotate(30deg);
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient( to right, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0.0) 100%);
}

.all-games-col:active:after {
  opacity: 0;
}
.all-games-col:after {
  animation: shine 3s ease-in-out infinite;
  animation-fill-mode: forwards;
}
.all-games-col:after {
  animation: shine 3s ease-in-out infinite;
  animation-fill-mode: forwards;
}
a { color: #fff; }
a:hover { color: #fff; }
a:visited { color: #fff; }
.carousel-item-right img { width: unset !important; float: right; padding: right 2rem; max-width: 275px;}
.nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {color:#FFC34B !important;}