@media only screen and (min-width: 260px) {
    #myChart {
        margin-left: -2rem !important;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #myChart {
        margin-left: -2rem !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    #myChart {
        margin-left: 2rem !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    #myChart {
        margin-left: -1rem !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    #myChart {
        margin-left: -4rem !important;
    }
}

@media only screen and (min-width: 1200px) {
    #myChart {
        margin-left: -5rem !important;
    }
}